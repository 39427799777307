import axios from 'axios';
import { isNotNilOrEmpty } from '@paravano/utils';

export const fetchImage = async (productCode, token, items) => {
  const image = items.find(item => item.productCode === productCode);
  
  if (isNotNilOrEmpty(image)) {
    return image;
  }

  try {
    const response = await axios.get('https://api.prod.dpc.nike.io/via/v1/dpc/images/product?productCode=' + productCode + '&height=400&width=400', {headers: {"Authorization": `Bearer ${token}`}, responseType: 'arraybuffer'});
    const image = Buffer.from(response.data, 'binary').toString('base64');

    return {
      productCode,
      image,
    };
  } catch (error) {
    return {
      productCode,
    };
  }
};

export const fetchImages = async (productCode, token, items) => {
  const image = items.find(item => item.productCode === productCode);

  if (isNotNilOrEmpty(image)) {
    return;
  }

  try {
    const response = await axios.get('https://api.prod.dpc.nike.io/via/v1/dpc/images/product/search?productCode=' + productCode, {headers: {"Authorization": `Bearer ${token}`}});
    let images = [];

    for await (const item of response.data.results[0]?.assets) {
      const viewCode = item.metadata.viewCode.toString();

      try {
        const response = await axios.get('https://api.prod.dpc.nike.io/via/v1/dpc/images/product?productCode=' + productCode + '&viewCode=' + viewCode + '&width=600&height=600', {headers: {"Authorization": `Bearer ${token}`}, responseType: 'arraybuffer'});
        const image = Buffer.from(response.data, 'binary').toString('base64');

        images.push(image);
      } catch (error) {
        // console.error(error);
      }
    }

    return {
      productCode,
      images,
    };
  } catch (error) {
    return {
      productCode,
    };
  }
};