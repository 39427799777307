import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { isNilOrEmpty, isNotNilOrEmpty, kebab, removeAt } from '@paravano/utils';
import { Button, Card, Modal, Snack, Spinner, Text, Link as TextLink, Tabs, TextArea, Toggle } from "@nike/eds";
import { CarouselStyled } from "@nike/nike-design-system-components";
import { SelectField, Skeleton, TextField } from '@nike/admin-design-system-components';

import { AppContext, useAppContext } from '../../app/utils/context';
import { fetchImage, fetchImages } from '../../app/utils/fetchImages';
import { Restricted } from '../../app/components/Restricted';
import { TabsWrapper } from '../../app/components/TabsWrapper';
import { Accordion } from '../../app/components/Accordion';
import EditComponent from './edit';

const modalCss = css({
  width: 400,
});

const Page = props => {
  const { t } = useTranslation();
  const { appProps } = useAppContext();
  const { setAppProps } = useContext(AppContext);
  const [productCode, setProductCode] = useState(props.match.params.id);
  const [thumbnail, setThumbnail] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [showGallery, setShowGallery] = useState('');
  const [addNote, setAddNote] = useState(false);
  const [tabs, setTabs] = useState([t('Base Product')]);
  const [activeTab, setActiveTab] = useState(0);
  
  useEffect(() => {
    setAppProps({...appProps, section: 'Classification Detail'});
  }, []);

  useEffect(() => {
    fetchThumbnail(productCode);
  }, [appProps]);

  const fetchThumbnail = async (code) => {
    const _thumbnail = await fetchImage(code, appProps.token, thumbnail)
    setThumbnail([_thumbnail]);
  };

  const onCancelAddNote = () => {
    setAddNote(false);
  };

  const onOpenGallery = async productCode => {
    setShowGallery(productCode);

    const fetchedImages = await fetchImages(productCode, appProps.token, gallery);

    if (isNotNilOrEmpty(fetchedImages)) {
      setGallery([
        ...gallery,
        fetchedImages
      ]);
    }
  };
  
  const onCloseGallery = () => {
    setShowGallery('');
  };

  const renderGallery = () => {
    const product = gallery.find(item => item.productCode === showGallery);

    if (isNilOrEmpty(product)) {
      return <Spinner size="large" />;
    }

    if (isNilOrEmpty(product.images)) {
      return;
    }
   
    return <CarouselStyled showPeek={{ xs: false, s: false, m: false, l: false, xl: false }}>
      {product.images?.map((image, index) => <div className="gallery-image" key={`${gallery}-image-${index}`}><img src={`data:image/png;base64,${image}`} /></div>)}
    </CarouselStyled>
  };

  const renderImage = productCode => {
    const product = thumbnail.find(item => item.productCode === productCode);

    if (isNilOrEmpty(product)) {
      return <div className="product-image product-image-large eds-spacing--mb-20"><Skeleton height="600px" width="600px" /></div>;
    }

    if (isNilOrEmpty(product.image)) {
      return;
    }

    return <div className="product-image product-image-large product-image-link eds-spacing--mb-20" onClick={() => onOpenGallery(productCode)}><img alt={product?.productCode} src={`data:image/png;base64,${product?.image}`} /></div>;
  }

  if (!appProps.permissions?.classification) {
    return <Restricted />;
  }

  return <>
    <div className="d-sm-flx flx-ai-sm-c eds-spacing--mb-60">
      <Text className="flx-gro-sm-1" font={"title-2"} as={"h1"}>{productCode.includes(',') ? 'Bulk' : productCode} {t('Classification Detail')}</Text>
      <div className="d-sm-flx flx-ai-sm-c">
        <Button className="eds-spacing--ml-16">{t('Update')}</Button>
      </div>
    </div>
    <div className="ncss-row eds-spacing--mt-32">
      <div className="ncss-col-sm-3 eds-va-t">
        <Snack className="eds-snack-clean eds-spacing--mb-32" status="success"><b>Status:</b> Approved</Snack>
        <Accordion
          className="eds-spacing--mb-24"
          headerSlot={<Text font={"title-6"} as={"h3"}>{t('Product Information')}</Text>}
          isExpanded>
          {renderImage(productCode)}
          <ul>
            <li><b>{t('Compound Type')}:</b> Individual</li>
            <li><b>{t('Product Code')}:</b> {productCode}</li>
            <li><b>{t('Product Type')}:</b> Inline</li>
            <li><b>{t('Company')}:</b>	Nike</li>
            <li><b>{t('Global Category')}:</b> Nike Sportswear</li>
            <li><b>{t('Product Name')}:</b> Nk Sfs Rspdr Bkpk</li>
            <li><b>{t('Silhouette')}:</b> Backpack</li>
            <li><b>{t('Gender Age')}:</b> Mens</li>
            <li><b>{t('Sports Activity')}:</b> Basic/Active</li>
          </ul>
        </Accordion>
        <Accordion
          className="eds-spacing--mb-24"
          headerSlot={<Text font={"title-6"} as={"h3"}>{t('Notes')}</Text>}
          isExpanded>
          <div>
            <p>Lorem ipsum doler sit amet.</p>
            <p><small><i>2014-01-18	| Lorem ipsum doler sit amet.</i></small></p>
          </div>
          <hr className="eds-hr" />
          <div>
            <p>Lorem ipsum doler sit amet.</p>
            <p><small><i>2014-01-18	| Lorem ipsum doler sit amet.</i></small></p>
          </div>
          <hr className="eds-hr" />
          <div className="d-sm-flx flx-jc-sm-fe">
            <TextLink onClick={() => setAddNote(true)}>+ Add Note</TextLink>
          </div>
        </Accordion>
        <Accordion
          className="eds-spacing--mb-24"
          headerSlot={<Text font={"title-6"} as={"h3"}>{t('History')}</Text>}>
        </Accordion>
      </div>
      <div className="ncss-col-sm-9 eds-spacing--pl-32">
        <div className="d-sm-flx eds-spacing--mb-20">
          <TabsWrapper>
            {tabs.length > 1 && <Tabs
              onChangeTab={index => setActiveTab(index)}
              headers={tabs}
              activeTab={activeTab}
            />}
          </TabsWrapper>
          <TextLink className="eds-spacing--ml-20" onClick={() => {
            setTabs(tabs => [...tabs, `Subproduct ${tabs.length}`]);
            setActiveTab(tabs.length);
          }}>+ Add Subproduct</TextLink>
        </div>
        {tabs.map((tab, index) => <EditComponent show={index === activeTab} key={`edit-${index}`} subproduct={index > 0} substatus={tabs.length > 1} />)}
      </div>
    </div>
    <Modal
      isOpen={isNotNilOrEmpty(showGallery)}
      onDismiss={() => onCloseGallery()}
      headerSlot={<Text font={"title-3"} as={"h3"}>{showGallery}</Text>}>
        <div className="eds-gallery">
          {renderGallery()}
        </div>
    </Modal>
    <Modal
      isOpen={addNote}
      onDismiss={() => onCancelAddNote()}
      headerSlot={<Text font={"title-3"} as={"h3"}>{t('Add Note')}</Text>}
      footerSlot={<>
        <Button className="eds-spacing--mr-12" onClick={() => onCancelAddNote()} size="small">{t('Save')}</Button>
        <Button onClick={() => onCancelAddNote()} size="small" variant="secondary">{t('Cancel')}</Button>
      </>}>
        <div className={modalCss}>
          <SelectField className="eds-spacing--mb-20" color="secondary" label={t('Type')} />
          <TextArea color="secondary" label={<Text as="h4" className="eds-spacing--mb-16" font="title-6">{t('Note')}</Text>} />
        </div>
    </Modal>
  </>
};

export default Page;