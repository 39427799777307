import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CaretDown, CaretUp } from '@nike/nike-design-system-icons';

export const Accordion = ({children, isExpanded = false, headerSlot, id, onChange, ...other}) => {
  const [expanded, setExpanded] = useState(isExpanded ? true : false);

  const onClick = () => {
    setExpanded(!expanded);
    
    if (onChange) {
      onChange({id, isExpanded: !expanded});
    };
  };

  return <div {...other}>
    <div className="eds-accordion__header" onClick={() => onClick()}>
      <div className="eds-accordion__title">{headerSlot}</div>
      {expanded ? <CaretUp /> : <CaretDown />}
    </div>
    <div className="eds-accordion__divider" />
    {expanded && <div className="eds-accordion__content">
      {children}
    </div>}
  </div>
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  headerSlot: PropTypes.node.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func,
};