import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalize, kebab } from '@paravano/utils';
import { Button, Card, Snack, Text, TextArea, Link as TextLink, Toggle } from "@nike/eds";
import { SelectField, TextField } from '@nike/admin-design-system-components';
import { Edit, Whiteboard } from '@nike/nike-design-system-icons';

import { SidePanel } from '../../app/components/SidePanel';

import { sections } from './equipment';

const Component = props => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [productSelections, setProductSelections] = useState([]);

  const renderSections = () => sections.map((section, sectionIndex) => (
    <div className="ncss-row eds-spacing--mb-32" key={`section-${sectionIndex}`}>
      {section.fields.map((field, fieldIndex) => {
        let options = [];

        field.options?.map(option => {
          options.push({ label: capitalize(option.toLowerCase()), value: kebab(option) });
        });

        return <div className={`ncss-col-sm-${field.full ? '12' : '6'} eds-spacing--mt-20 eds-va-t`} key={`field-${fieldIndex}`}>
          {field.type === "select" && <SelectField color="secondary" label={field.label} onChange={e => onChangeField(e, section, field, 'select')} options={options} value={kebab(productSelections.filter(item => item.label === field.label)[0]?.value) ?? ''} />}
          {field.type === "checkbox" && <>
            <Text className="eds-spacing--mb-16" font={"title-6"} as={"h3"}>{field.label}</Text>
            <Toggle name={field.label} labelProps={{ style: {font: "var(--eds-type-body-2)"} }} onChange={e => onChangeField(e, section, field, 'checkbox')} />
          </>}
          {field.type === "text" && <>
            <TextField color="secondary" label={field.label} onChange={e => onChangeField(e, section, field, 'text')} />
          </>}
          {field.type === "textarea" && <>
            <TextArea color="secondary" label={<Text as="h4" className="eds-spacing--mb-16" font="title-6">{t(field.label)}</Text>} />
          </>}
        </div>;
      })}
    </div>
    )
  );

  const onChangeField = (e, section, field, type) => {
    const selections = productSelections.filter(item => item.label !== field.label);
    
    if (type === "date" || type === 'text') {
      setProductSelections([
        ...selections,
        {
          label: field.label,
          section,
          value: e.target.value,
        }
      ]);
    }

    if (type === "select") {
      setProductSelections([
        ...selections,
        {
          label: field.label,
          section,
          value: e.target.innerText,
        }
      ]);
    }

    if (type === "checkbox") {
      setProductSelections([
        ...selections,
        {
          label: field.label,
          section,
          value: e.target.checked,
        }
      ]);
    }
    
  };

  return (
    <div className={!props.show && 'eds-hidden'}>
      <div className="d-sm-flx flx-ai-sm-c eds-spacing--mb-16">
        <div className="flx-gro-sm-1">
          {props.substatus && <Snack className="eds-snack-clean eds-snack-small" status="success"><b>Substatus:</b> Approved</Snack>}
        </div>
      </div>
      {sections.map((section, sectionIndex) => (
        <Card className="eds-spacing--mb-16" key={`section-read-${sectionIndex}`}>
          <div className="d-sm-flx flx-ai-sm-c">
            <Text className="flx-gro-sm-1" font={"title-5"} as={"h2"}><Whiteboard /> {t(section.name)}</Text>
            <TextLink onClick={() => setEdit(true)}><Edit /> Edit</TextLink>
          </div>
          <div className="ncss-row eds-spacing--mt-4">
            {productSelections.filter(x => x.section.name === section.name).map((field, index) => <div className="ncss-col-sm-6 eds-spacing--mt-16 eds-va-t" key={`selection-${index}`}><Card className="eds-card-small eds-card-gray"><b>{field.label}:</b> {field.value == true ? 'true' : field.value}</Card></div>
            )}
          </div>
        </Card>
      ))}
      {/* {props.subproduct && <Button className="eds-spacing--mt-16" variant="secondary">{t('Remove Subproduct')}</Button>} */}
      <SidePanel
        footerSlot={() => <>
          <Button className="eds-spacing--mr-12" onClick={() => setEdit(false)}>{t('Apply')}</Button>
          <Button onClick={() => setEdit(false)} variant="secondary">{t('Cancel')}</Button>
        </>}
        headerSlot={() => <h3 className="eds-type--title-3">{t('Edit')}</h3>}
        isOpen={edit}
        onDismiss={() => setEdit(false)}>
          {renderSections()}
      </SidePanel>
    </div>
  );
};

export default Component;