export const groups = [
  {
    name: "General",
    fields: [
      {
        label: "Status",
        full: true,
        type: "select",
        options: [],
      },
      {
        label: "Import Country",
        type: "select",
        options: [],
      },
      {
        label: "Division",
        type: "select",
        options: [],
      },
      {
        label: "Product Group",
        type: "select",
        options: [],
      },
      {
        label: "Subgroup",
        type: "select",
        options: [],
      },
      {
        label: "Modified Date From",
        type: "date"
      },
      {
        label: "Modified Date To",
        type: "date"
      }
    ],
  },
]