import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { capitalize, fuzzyMatch, isNilOrEmpty, isNotNilOrEmpty, kebab } from '@paravano/utils';
import { Button, Chip, ChipGroup, Modal, Popover, Radio, Snack, Snackbar, Spinner, Text, Link as TextLink, Toggle } from "@nike/eds";
import { CarouselStyled } from "@nike/nike-design-system-components";
import { SelectField, Skeleton, TextField } from '@nike/admin-design-system-components';
import { Ellipsis, Search } from '@nike/nike-design-system-icons';

import { AppContext, useAppContext } from '../app/utils/context';
import { fetchImage, fetchImages } from '../app/utils/fetchImages';
import { Restricted } from '../app/components/Restricted';
import { SidePanel } from '../app/components/SidePanel';
import { Accordion } from '../app/components/Accordion';
import { Table } from '../app/components/DataTable';

import { groups } from './filtersGroups.js';
import { sample } from '../app/data';

const modalCss = css({
  width: 400,
  '.eds-inputs__radio': {
    marginLeft: 0,
    label: {
      marginTop: 2,
    },
  },
  p: {
    color: '#6a6a6a',
  },
});

const errorCss = css({
  color: 'var(--eds-color-text-danger) !important',
});

const Page = () => {
  const { t } = useTranslation();
  const { appProps } = useAppContext();
  const { setAppProps } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [query, setQuery] = useState('');
  const [showAction, setShowAction] = useState('');
  const [cardView, setCardView] = useState(JSON.parse(localStorage.getItem('cardView')) ?? false);
  const [filterSections, setFilterSections] = useState(JSON.parse(localStorage.getItem('filterSections')) ?? []);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [clearSelected, setClearSelected] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [showSaveFilters, setShowSaveFilters] = useState(false);
  const [showLoadFilters, setShowLoadFilters] = useState(false);
  const [showSaveFilterConfirmation, setShowSaveFilterConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [filterSelections, setFilterSelections] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [showGallery, setShowGallery] = useState('');

  useEffect(() => {
    setAppProps({...appProps, section: 'Attributes'});
    setData(sample);
  }, []);

  if (!appProps.permissions?.attributes) {
    return <Restricted />;
  }
  
  const renderImage = productCode => {
    const product = thumbnails.find(item => item.productCode === productCode);

    if (isNilOrEmpty(product)) {
      return <div className="product-image"><Skeleton height="600px" width="600px" /></div>;
    }

    if (isNilOrEmpty(product.image)) {
      return;
    }

    return <div className="product-image product-image-link" onClick={() => onOpenGallery(productCode)}><img alt={product?.productCode} src={`data:image/png;base64,${product?.image}`} /></div>;
  }

  const columns = [
    {
      cell: row => <div className="product">
        {renderImage(row.code)}
        <Link className="eds-link" to={`/attributes/${row.code}`}>{row.code}</Link>
      </div>,
      grow: 2,
      name: t('Product Code'),
      selector: row => row.code,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.name.toLowerCase())}</p>,
      grow: 2,
      name: t('Product Name'),
      selector: row => row.name,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.group.toLowerCase())}</p>,
      name: t('Product Group'),
      selector: row => row.group,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.status.toLowerCase())}</p>,
      name: t('Status'),
      selector: row => row.status,
      sortable: true,
    },
    {
      cell: row => <p>{capitalize(row.silhouette.toLowerCase())}</p>,
      name: t('Silhouette'),
      selector: row => row.silhouette,
      sortable: true,
    },
    {
      cell: row => <p>{row.importCountry}</p>,
      name: t('Import Country'),
      selector: row => row.importCountry,
      sortable: true,
    },
    {
      cell: row => <Ellipsis onClick={() => setShowAction(row)} />,
      // cell: row => <Popover
      //   onClose={() => setShowAction('')}
      //   isOpen={showAction.contains(row)}
      //   // headerSlot={header}
      //   // bodySlot={body}
      //   // footerSlot={footer}
      //   >
      //   <Ellipsis onClick={() => setShowAction(row)} />
      // </Popover>,
      center: true,
      maxWidth: '40px',
      name: t('Actions'),
    },
  ];
    
  const onSearch = async value => {
    setClearSelected(!clearSelected);
    setSelected([]);

    if (isNilOrEmpty(value)) {
      setFilteredData([]);
      setQuery(value);
      return;
    }

    const _value = value.toLowerCase();
    const filtered = data.filter(item => fuzzyMatch(item.code, _value) || fuzzyMatch(item.name, _value));

    setFilteredData(filtered);
    setQuery(value);
    
    const promises = filtered.map(item => fetchImage(item.code, appProps.token, thumbnails));
    const fetchedThumbnails = (await Promise.all(promises)).filter(x => isNotNilOrEmpty(x));

    setThumbnails(fetchedThumbnails);
  };

  const onOpenGallery = async productCode => {
    setShowGallery(productCode);

    const fetchedImages = await fetchImages(productCode, appProps.token, gallery);

    if (isNotNilOrEmpty(fetchedImages)) {
      setGallery([
        ...gallery,
        fetchedImages
      ]);
    }
  };
  
  const onCloseGallery = () => {
    setShowGallery('');
  };

  const renderGallery = () => {
    const product = gallery.find(item => item.productCode === showGallery);

    if (isNilOrEmpty(product)) {
      return <Spinner size="large" />;
    }

    if (isNilOrEmpty(product.images)) {
      return;
    }
   
    return <CarouselStyled showPeek={{ xs: false, s: false, m: false, l: false, xl: false }}>
      {product.images?.map((image, index) => <div className="gallery-image" key={`${gallery}-image-${index}`}><img src={`data:image/png;base64,${image}`} /></div>)}
    </CarouselStyled>
  };

  const onSaveFilter = () => {
    const input = document.getElementById('save-filter');
    const name = input.value;
    const savedFilters = JSON.parse(localStorage.getItem('savedFilters')) ?? [];

    if (isNilOrEmpty(name)) {
      setErrorMessage('Please enter a name.');
      return null;
    }

    if (savedFilters.filter(e => e.name === name).length > 0) {
      setErrorMessage('Please choose a filter name that does not already exist.');
      return null;
    }

    localStorage.setItem('savedFilters', JSON.stringify([...savedFilters, { name, filters }]));
    setShowSaveFilters(false);
    setShowSaveFilterConfirmation(true);
    input.value = '';
  };

  const onCancelSaveFilters = () => {
    document.getElementById('save-filter').value = '';
    setShowSaveFilters(false);
    setErrorMessage('');
  };

  const onLoadFilter = () => {
    const radio = document.querySelector('input[name="saved-filters"]:checked');

    if (isNilOrEmpty(radio?.value)) {
      setErrorMessage('Please choose a filter.');
      return null;
    }

    setFilters(JSON.parse(radio?.value));
    setFilterSelections(JSON.parse(radio?.value));
    setShowLoadFilters(false);
    radio.checked = false;
  };

  const onCancelLoadFilters = () => {
    const radio = document.querySelector('input[name="saved-filters"]:checked');

    setShowLoadFilters(false);
    setErrorMessage('');

    if (isNotNilOrEmpty(radio)) {
      radio.checked = false;
    }
  };

  const onClearFilters = () => {
    setFilters([]);
    setFilterSelections([]);
  };

  const onApplyFilters = () => {
    setShowFilters(false);
    setFilters(filterSelections);
  };

  const onCancelFilters = () => {
    setShowFilters(false);
  };

  const renderSaveFilterConfirmation = () => {
    setTimeout(() => setShowSaveFilterConfirmation(false), 3000);

    return <Snackbar>
      <Snack status="success">
        {t('Your filter has been saved sucessfully.')}
      </Snack>
    </Snackbar>
  };

  const renderError = () => <p className={`eds-type--body-3 eds-spacing--mb-16 ${errorCss}`}>{t(errorMessage)}</p>

  const onChangeView = () => {
    localStorage.setItem('cardView', !cardView);
    setCardView(!cardView);
  }

  const onToggleFilterSection = e => {
    if (!e.isExpanded) {
      const sections = filterSections.filter(item => item != e.id);
      setFilterSections(sections)
      localStorage.setItem('filterSections', JSON.stringify(sections));
      return;
    }

    const sections = [...filterSections, e.id];
    setFilterSections(sections)
    localStorage.setItem('filterSections', JSON.stringify(sections));
  };

  const renderFilterGroups = () => groups.map((group, groupIndex) => <Accordion
    className="eds-spacing--mb-16"
    headerSlot={<Text font={"title-5"} as={"h3"}>{t(`${group.name}`)}</Text>}
    id={`accordion-${kebab(group.name)}`}
    isExpanded={filterSections.includes(`accordion-${kebab(group.name)}`)}
    key={`group-${groupIndex}`}
    onChange={e => onToggleFilterSection(e)}>
    <div className="ncss-row eds-spacing--mb-32">
      {group.fields.map((field, fieldIndex) => {
        let options = [];

        field.options?.map(option => {
          options.push({ label: capitalize(option.toLowerCase()), value: kebab(option) });
        });

        return <div className={`ncss-col-sm-${field.full ? '12' : '6'} eds-spacing--mt-20`} key={`field-${fieldIndex}`}>
          {field.type === "date" && <TextField color="secondary" label={field.label} onChange={e => onChangeFilterField(e, field.label, 'date')} type="date" value={filterSelections.filter(item => item.label === field.label)[0]?.value ?? ''} />}
          {field.type === "select" && <SelectField color="secondary" label={field.label} onChange={e => onChangeFilterField(e, field.label, 'select')} options={options} value={kebab(filterSelections.filter(item => item.label === field.label)[0]?.value) ?? ''}/>}
        </div>;
      })}
    </div>
  </Accordion>);

  const onChangeFilterField = (e, label, type) => {
    const selections = filterSelections.filter(item => item.label !== label);
    
    if (type === "date") {
      setFilterSelections([
        ...selections,
        {
          label,
          value: e.target.value,
        }
      ]);
    }

    if (type === "select") {
      setFilterSelections([
        ...selections,
        {
          label,
          value: e.target.innerText,
        }
      ]);
    }
    
  };

  return <>
    <div className="d-sm-flx flx-ai-sm-c eds-spacing--mb-40">
      <Text className="flx-gro-sm-1" font={"title-2"} as={"h1"}>{t('Product Attributes')}</Text>
      <div className="d-sm-flx flx-ai-sm-c">
        <Toggle checked={cardView} className="eds-spacing--mr-32" label="Card View" onClick={() => onChangeView()} />
        <Button>{t('Create Attributes')}</Button>
      </div>
    </div>
    <Text className="eds-spacing--mb-16" font={"title-4"} as={"h2"}>{t('Search and Filter')}</Text>
    <div className="eds-spacing--mb-40">
      <div className="d-sm-flx flx-ai-sm-c">
        <div id="product-attributes-search-wrapper" className="flx-gro-sm-1 eds-spacing--mr-32">
          <TextField onChange={e => onSearch(e.target.value)} placeholder={t('Search by Product Code, Base Style Number or Product Name')} startAdornment={<Search />} value={query} />
        </div>
        <div>
          <Button className="flx-gro-sm-1 eds-spacing--mr-16" onClick={() => setShowFilters(true)} variant="secondary">{t('Select Filters')}</Button>
          {isNotNilOrEmpty(localStorage.getItem('savedFilters')) && <Button onClick={() => setShowLoadFilters(true)} variant="ghost">{t('Saved Filters')}</Button>}
        </div>
      </div>
      {isNotNilOrEmpty(filters) && <div className="d-sm-flx flx-ai-sm-c eds-spacing--mt-16">
        <div className="eds-spacing--mr-8">
          <h4 className="eds-type--subtitle-1">{t('Filters')} ({filters.length})</h4>
          <p className="eds-type--subtitle-2"><TextLink onClick={() => onClearFilters()}>{t('Clear')}</TextLink> | <TextLink onClick={() => setShowSaveFilters(true)}>{t('Save')}</TextLink></p>
        </div>
        <ChipGroup>
          {filters.map((filter, i) => <Chip key={`filter=${i}`} label={`${filter.label}: ${filter.value}`} onClose={() => {
            const updatedFilters = filters.filter(item => item.label != filter.label);
            setFilters(updatedFilters);
            setFilterSelections(updatedFilters);
          }} />)}
        </ChipGroup>
      </div>}
    </div>
    <Text className="eds-spacing--mb-16" font={"title-4"} as={"h2"}>{t('Search Results')}</Text>
    {isNotNilOrEmpty(filteredData) && <div className="d-sm-flx flx-ai-sm-c eds-spacing--mb-16">
      <div className="flx-gro-sm-1">
        <TextLink>{t('Export')} {isNotNilOrEmpty(selected) ? selected.length : 'All'} {t('Items')}</TextLink>
        {isNotNilOrEmpty(selected) && <span> | <TextLink onClick={() => {setClearSelected(!clearSelected); setSelected([])}}>{t('Deselect All')}</TextLink></span>}
      </div>
      <p>{filteredData.length} {t('Items Found')}</p>
    </div>}
    <Table cards={cardView} clear={clearSelected} columns={columns} data={filteredData} loading={loading} onSelection={e => setSelected(e.selectedRows)} splash={isNilOrEmpty(query)} />
    <SidePanel
      footerSlot={() => <>
        <Button className="eds-spacing--mr-12" onClick={() => onApplyFilters()}>{t('Apply')}</Button>
        <Button onClick={() => onCancelFilters()} variant="secondary">{t('Cancel')}</Button>
      </>}
      headerSlot={() => <h3 className="eds-type--title-3">{t('Select Filters')}</h3>}
      isOpen={showFilters}
      onDismiss={() => onCancelFilters()}>
        {renderFilterGroups()}
    </SidePanel>
    <Modal
      isOpen={showSaveFilters}
      onDismiss={() => onCancelSaveFilters()}
      headerSlot={<Text font={"title-3"} as={"h3"}>{t('Save Filter')}</Text>}
      footerSlot={<>
        <Button className="eds-spacing--mr-12" onClick={() => onSaveFilter()} size="small">{t('Save')}</Button>
        <Button onClick={() => onCancelSaveFilters()} size="small" variant="secondary">{t('Cancel')}</Button>
      </>}>
        <div className={modalCss}>
          <TextField className="eds-spacing--mb-16" color="secondary" id="save-filter" placeholder={t('Filter Name')} />
          {isNotNilOrEmpty(errorMessage) && renderError()}
          <p className="eds-type--body-3">{filters.map((filter, i) => `${filter.label}: ${filter.value}${i < (filters.length - 1) ? ', ' : ''}`)}</p>
        </div>
    </Modal>
    <Modal
      isOpen={showLoadFilters}
      onDismiss={() => onCancelLoadFilters()}
      headerSlot={<Text font={"title-3"} as={"h3"}>{t('Saved Filters')}</Text>}
      footerSlot={<>
        <Button className="eds-spacing--mr-12" onClick={() => onLoadFilter()} size="small">{t('Load')}</Button>
        <Button onClick={() => onCancelLoadFilters()} size="small" variant="secondary">{t('Cancel')}</Button>
      </>}>
        <div className={modalCss}>
          {isNotNilOrEmpty(errorMessage) && renderError()}
          {JSON.parse(localStorage.getItem('savedFilters'))?.map((filters, i) => <div className="eds-spacing--mb-16" key={`load-filter-${i}`}>
            <Radio name="saved-filters" label={filters.name} value={JSON.stringify(filters.filters)} />
            <p className="eds-type--body-3 eds-spacing--ml-36">{filters.filters.map((filter, i) => `${filter.label}: ${filter.value}${i < (filters.filters.length - 1) ? ', ' : ''}`)}</p>
          </div>)}
        </div>
    </Modal>
    {showSaveFilterConfirmation && renderSaveFilterConfirmation()}
    <Modal
      isOpen={isNotNilOrEmpty(showGallery)}
      onDismiss={() => onCloseGallery()}
      headerSlot={<Text font={"title-3"} as={"h3"}>{showGallery}</Text>}>
        <div className="eds-gallery">
          {renderGallery()}
        </div>
    </Modal>
  </>
};

export default Page;