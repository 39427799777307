import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useScrolledToEdge } from 'scrolled-to-edge';
import { cx } from '@emotion/css'

export const TabsWrapper = ({children}) => {
  const [scrollPosition, setScrollPosition] = useState(null);

  const content = useScrolledToEdge(e => {
    setScrollPosition(e.x);
  });
  
  const classes = cx({
    ['eds-tabs-wrapper__scrollleft']: scrollPosition === 'start' || scrollPosition === null,
    ['eds-tabs-wrapper__scrollright']: scrollPosition === 'end' || scrollPosition === null,
  }, 'eds-tabs-wrapper');

  return (
    <div className={classes}>
      <div ref={content}>
        {children}
      </div>
    </div>
  );
};

TabsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};