import merge from "deepmerge";

const base = {
  appFullName: "NTN 2 UI",
  appName: "NTN 2 UI",
  description: "Used for the NTN 2.0 React App",
  oAuth: {
    clientId: "nike.niketech.ntn2-ui",
    redirectUri: window.location.origin,
  },
};

const dev = merge(base, {
  isProd: false,
});

const prod = merge(base, {
  isProd: true,
});

let env = prod;
// if (window.location.origin.includes("localhost:3000")) {
//   env = dev;
// }
const config = env;
export default config;