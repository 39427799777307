import Home from './home';
import Attributes from './attributes';
import AttributesDetail from './attributes/detail';
import Classification from './classification';
import ClassificationDetail from './classification/detail';
import Settings from './settings';

const routes = [
  {
    component: Home,
    exact: true,
    path: '/',
  },
  {
    component: Attributes,
    exact: true,
    path: '/attributes',
  },
  {
    component: AttributesDetail,
    exact: true,
    path: '/attributes/:id',
  },
  {
    component: Classification,
    exact: true,
    path: '/classification',
  },
  {
    component: ClassificationDetail,
    exact: true,
    path: '/classification/:id',
  },
  {
    component: Settings,
    exact: true,
    path: '/settings',
  }
];

export default routes;