import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useScrolledToEdge } from 'scrolled-to-edge';
import { cx } from '@emotion/css'

import { Close } from '@nike/nike-design-system-icons';

export const SidePanel = ({isOpen, children, footerSlot, headerSlot, large, onDismiss}) => {
  const [scrollPosition, setScrollPosition] = useState(null);

  const content = useScrolledToEdge(e => {
    setScrollPosition(e.y);
  });
  
  const scrimClasses = cx({
    ['eds-scrim--hidden']: !isOpen,
  }, 'eds-scrim');
  
  const contentClasses = cx({
    ['eds-side-panel__scrolltop']: scrollPosition === 'start' || scrollPosition === null,
    ['eds-side-panel__scrollbottom']: scrollPosition === 'end' || scrollPosition === null,
  }, 'eds-side-panel__content');

  const panelClasses = cx('eds-side-panel', {
    ['eds-side-panel--large']: large,
  });

  if (!isOpen) {
    return null;
  };

  return <aside
    className={scrimClasses}>
    <div className={panelClasses}>
      <div className="eds-side-panel__header">
        {headerSlot()}
        <button
          className="eds-side-panel__close"
          style={{}}
          onClick={() => onDismiss()}>
          <Close />
        </button>
      </div>
      <div className={contentClasses}>
        <div ref={content}>
          {children}
        </div>
      </div>
      {footerSlot && (
        <>
          <div className="eds-side-panel__divider" />
          <div className="eds-side-panel__buttons">{footerSlot()}</div>
        </>
      )}
    </div>
  </aside>
};

SidePanel.propTypes = {
  children: PropTypes.node.isRequired,
  footerSlot: PropTypes.func,
  headerSlot: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  large: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
};