export const sections = [
  {
    name: "Classification",
    fields: [
      {
        label: "HTS",
        type: "text",
      },
      {
        label: "Price Transition HTS",
        type: "select",
        options: [],
      },
      {
        label: "Status",
        type: "select",
        options: [],
      },
      {
        label: "Ruling",
        type: "select",
        options: [],
      },
      {
        label: "Custom Description",
        type: "textarea",
        full: true,
      },
      {
        label: "Detail Description",
        type: "textarea",
        full: true,
      },
      {
        label: "DO Description",
        type: "textarea",
        full: true,
      },
      {
        label: "Ruling Description",
        type: "textarea",
        full: true,
      },
    ],
  }
]