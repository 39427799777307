export const groups = [
  {
    name: "Bag",
    subGroups: [
      "",
      "Duffel/Travel",
      "Gym Sack",
      "Back Pack",
      "Satchel",
      "Hand Bag",
      "Travel Kit/Toiletry Bag",
      "Baseball Bat Bag",
      "Shoe Bag",
      "Waistpack",
      "Tote",
    ]
  },
  {
    name: "Glove",
    subGroups: [
      "",
      "Fitness Glove",
      "Non Sport Glove",
      "Soccer Goalie Glove",      
      "Soccer Field Player Glove",
    ]
  },
  {
    name: "Accessory",
    subGroups: [
      "",
      "Ball Pump",
      "Hair Sport Band",
      "Lanyard",
      "Other",
      "Sport Towel",
      "Support Brace",
      "Tee Sleeve Wrap",
      "Towel",
      "Umbrella",
      "Wallet",
      "Water Bottle",
    ]
  },
  {
    name: "Ball",
    subGroups: [
      "",
      "Basketball",
      "Football",
      "Rugby Football",
      "Soccer Ball",
      "Volleyball",
    ]
  },
  {
    name: "Digital Tech",
    subGroups: [
      "",
      "Other",
    ]
  },
  {
    name: "Headwear",
    subGroups: [
      "",
      "BANDANA",
      "Cap",
      "Hat",
      "Head Other",
      "Headband",
    ]
  },
  {
    name: "Other",
    subGroups: [
      "",
      "Bag",
      "Belt",
      "Legwarmers",
      "Pencil Case",
      "Scard",
      "Sleeve",
      "Wristband",
    ]
  },
  {
    name: "Protective",
    subGroups: [
      "",
      "Elbow Guard",
      "KneepadD",
      "Leg Guard",
      "Mouthguard",
      "Shinguard",
    ]
  },
  {
    name: "Sock",
    subGroups: [
      "",
      "Knee High",
      "Other",
    ]
  },
  {
    name: "Sports Fitness",
    subGroups: [
      "",
      "Other",
    ]
  },
  {
    name: "Vision",
    subGroups: [
      "",
      "Football Shiled",
      "Sunglasses",
    ]
  }
];

export const sections = [
  {
    name: "Fabric and Material",
    excludeGroup: [],
    includeGroup: [],
    fields: [
      {
        label: "Primary Fabric",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Primary Material",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Fiber",
        type: "percentage-creatable",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Lining",
        type: "select",
        options: ["Full Lining", "No Lining", "Not Applicable", "Partial Lining", "Partial Lining, Hood Only"],
        excludeGroup: ["Accessory: Ball Pump", "Accessory: Lanyard", "Accessory: Other", "Accessory: Sport Towel", "Accessory: Support Brace", "Accessory: Tee Sleeve Wrap", "Accessory: Towel", "Accessory: Umbrella", "Accessory: Wallet", "Accessory: Water Bottle", "Ball", "Digital Tech", "Other: Belt", "Other: Pencil Case", "Vision", "Other: Belt", "Other: Pencil Case"],
        excludeSelection: {
          ["No Lining"]: ["Lining"],
        },
        includeGroup: [],
        includeSelection: {},
      },
    ],
  },
  {
    name: "Lining",
    excludeGroup: ["Accessory: Ball Pump", "Accessory: Lanyard", "Accessory: Other", "Accessory: Sport Towel", "Accessory: Support Brace", "Accessory: Tee Sleeve Wrap", "Accessory: Towel", "Accessory: Umbrella", "Accessory: Wallet", "Accessory: Water Bottle", "Ball", "Digital Tech", "Other: Belt", "Other: Pencil Case"],
    includeGroup: [],
    fields: [
      {
        label: "Lining Type",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Lining Fabric",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Lining Material",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Lining Fiber",
        type: "percentage-creatable",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Seamless",
        type: "checkbox",
        options: [],
        excludeGroup: ["Bag: Waistpack"],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Reversible",
        type: "checkbox",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Reverse Fabric",
        type: "select",
        options: [],
        excludeGroup: ["Glove", "Glove: Fitness Glove", "Glove: Non Sport Glove", "Glove: Soccer Goalie Glove", "Glove: Soccer Field Player Glove"],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {
          ["Reversible"]: true,
        },
      },
      {
        label: "Reverse Material",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {
          ["Reversible"]: true,
        },
      },
      {
        label: "Reverse Fiber",
        type: "percentage-creatable",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {
          ["Reversible"]: true,
        },
      },
    ],
  },
  {
    name: "Palm Material",
    excludeGroup: [],
    includeGroup: ["Glove"],
    fields: [
      {
        label: "Palm material",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
      {
        label: "Palm Fiber",
        type: "percentage-creatable",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: [],
        includeSelection: {},
      },
    ],
  },
  {
    name: "General Construction",
    excludeGroup: ["Ball", "Digital Tech", "Other", "Protective", "Sports Fitness"],
    includeGroup: [],
    fields: [
      {
        label: "Net Lace",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Sock", "Sock: Knee High", "Sock: Other"],
        includeSelection: {},
      },
      {
        label: "Purpose",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Headwear: Headband", "Headwear: Head Other"],
        includeSelection: {},
      },
      {
        label: "Product Feature",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Bag: Duffel/Travel", "Bag: Back Pack", "Bag: Travel Kit/Toiletry Bag", "Bag: Baseball Bat Bag", "Glove: Fitness Glove", "Glove: Non Sport Glove", "Accessory: Ball Pump", "Accessory: Lanyard", "Headwear: Hat", "Headwear: Head Other", "Vision", "Vision: Football Shield", "Vision: Sunglasses"],
        includeSelection: {},
      },

      {
        label: "Padding",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Bag: Duffel/Travel", "Bag: Back Pack", "Bag: Travel Kit/Toiletry Bag", "Bag: Baseball Bat Bag"],
        includeSelection: {},
      },
      {
        label: "Closure",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Bag: Satchel", "Bag: Handbag", "Bag: Tote"],
        includeSelection: {},
      },

      {
        label: "Mitten",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Glove", "Glove: Fitness Glove", "Glove: Non Sport Glove", "Glove: Soccer Goalie Glove", "Glove: Soccer Field Player Glove"],
        includeSelection: {},
      },

      {
        label: "Divided Compartments",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Bag: Satchel", "Bag: Handbag", "Bag: Tote"],
        includeSelection: {},
      },
      {
        label: "Impregnated/Coated with Rubber/Plastic",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Glove", "Glove: Fitness Glove", "Glove: Non Sport Glove", "Glove: Soccer Goalie Glove", "Glove: Soccer Field Player Glove"],
        includeSelection: {},
      },
      {
        label: "Length",
        type: "text",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Bag: Satchel", "Bag: Handbag", "Bag: Tote"],
        includeSelection: {},
      },
      {
        label: "Width",
        type: "text",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Bag: Satchel", "Bag: Handbag", "Bag: Tote"],
        includeSelection: {},
      },
      {
        label: "Height",
        type: "text",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Bag: Satchel", "Bag: Handbag", "Bag: Tote"],
        includeSelection: {},
      },
      {
        label: "% of Rubber/Plastic Weights > = 50%",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Glove: Soccer Goalie Glove", "Glove: Soccer Field Player Glove"],
        includeSelection: {},
      },
      {
        label: "EQ Size",
        type: "select",
        options: [],
        excludeGroup: [],
        excludeSelection: [],
        includeGroup: ["Accessory: Water Bottle"],
        includeSelection: {},
      },
    ],
  },
]