import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { capitalize, isNilOrEmpty, isNotNilOrEmpty, kebab } from '@paravano/utils';
import { Button, Card, Snack, Text, Link as TextLink, Toggle } from "@nike/eds";
import { SelectField, TextField } from '@nike/admin-design-system-components';
import { Edit, Whiteboard, Minus } from '@nike/nike-design-system-icons';

import { SidePanel } from '../../app/components/SidePanel';
import { Accordion } from '../../app/components/Accordion';

import { groups, sections } from './equipment';

const PercentCreatable = props => {
  const [rows, setRows] = useState(1);

  return (
    <>
      {[...Array(rows)].map((x, index) => (
        <div className="ncss-col-sm-4 eds-spacing--mt-20 eds-va-t" key={`field-${index}`}>
          <Text font={"title-6"} as={"h3"}>{index === 0 ? props.label : <span>&nbsp;</span>}</Text>
          <div className="d-sm-flx flx-ai-sm-c" key={`percent-creatable-row-${index}`}>
            <div className="ncss-row eds-spacing--mb-8 flx-gro-sm-1">
              <div className="ncss-col-sm-3 eds-va-t">
                <TextField color="secondary" onChange={e => console.log(e)} placeholder="%" />
              </div>
              <div className={`ncss-col-sm-9 eds-va-t`}>
                <SelectField color="secondary" onChange={e => console.log(e)} options={props.options} />
              </div>
            </div>
          </div>
          <div className="eds-ta-r">
            <TextLink onClick={() => setRows(index + 1 === rows ? rows + 1 : rows - 1)}>{index + 1 === rows ? '+ Add' : '- Remove'}</TextLink>
          </div>
        </div>
      ))}
    </>
  );
};

const Component = props => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [detailSections, setDetailSections] = useState(JSON.parse(localStorage.getItem('detailSections')) ?? []);
  const [group, setGroup] = useState('');
  const [subGroup, setSubGroup] = useState('');
  const [productSelections, setProductSelections] = useState([]);
  
  const onToggleDetailSection = e => {
    if (!e.isExpanded) {
      const sections = detailSections.filter(item => item != e.id);
      setDetailSections(sections)
      localStorage.setItem('detailSections', JSON.stringify(sections));
      return;
    }

    const sections = [...detailSections, e.id];
    setDetailSections(sections)
    localStorage.setItem('detailSections', JSON.stringify(sections));
  };

  const renderSections = () => sections.map((section, sectionIndex) => {
    if (isNotNilOrEmpty(subGroup) && section.excludeGroup.includes(`${group}: ${subGroup}`)) {
      return null;
    } 
    
    if (section.excludeGroup.includes(group) || productSelections.find(x => x.excludedSection?.includes(section.name))) {
      return null;
    }

    if (isNotNilOrEmpty(section.includeGroup) && !section.includeGroup.includes(group)) {
      return null;
    }

    return (
      <Accordion
        className="eds-spacing--mb-16"
        headerSlot={<Text font={"title-5"} as={"h3"}>{t(`${section.name}`)}</Text>}
        id={`accordion-detail-${kebab(section.name)}`}
        isExpanded={detailSections.includes(`accordion-detail-${kebab(section.name)}`)}
        key={`section-${sectionIndex}`}
        onChange={e => onToggleDetailSection(e)}>
        <div className="ncss-row eds-spacing--mb-32">
          {section.fields.map((field, fieldIndex) => {
            if (isNotNilOrEmpty(field.includeSelection)) {
              for (const [key, value] of Object.entries(field.includeSelection)) {
                if (productSelections.filter(x => x.label === key && x.value === value).length < 1) {
                  return null;
                };
              }
            }

            if (isNotNilOrEmpty(field.includeGroup)) {
              if (isNilOrEmpty(subGroup) && !field.includeGroup.includes(group)) {
                return null;
              }
              
              if (isNotNilOrEmpty(subGroup) && !field.includeGroup.includes(`${group}: ${subGroup}`)) {
                return null;
              }
            }

            if (isNotNilOrEmpty(field.excludeGroup)) {
              if (isNilOrEmpty(subGroup) && field.excludeGroup.includes(group)) {
                return null;
              }
              
              if (isNotNilOrEmpty(subGroup) && field.excludeGroup.includes(`${group}: ${subGroup}`)) {
                return null;
              }
            }

            if (field.type === "percentage-creatable") {
              return <PercentCreatable label={field.label} options={options} />;
            }
            
            let options = [];

            field.options?.map(option => {
              options.push({ label: capitalize(option.toLowerCase()), value: kebab(option) });
            });

            if (field.type === "percentage-creatable") {
              return <PercentCreatable label={field.label} options={options} />;
            }

            return <div className={`ncss-col-sm-4 eds-spacing--mt-20 eds-va-t`} key={`field-${fieldIndex}`}>
              {field.type === "select" && <SelectField color="secondary" label={field.label} onChange={e => onChangeField(e, section, field, 'select')} options={options} value={kebab(productSelections.filter(item => item.label === field.label)[0]?.value) ?? ''} />}
              {field.type === "checkbox" && <>
                <Text className="eds-spacing--mb-16" font={"title-6"} as={"h3"}>{field.label}</Text>
                <Toggle name={field.label} labelProps={{ style: {font: "var(--eds-type-body-2)"} }} onChange={e => onChangeField(e, section, field, 'checkbox')} />
              </>}
              {field.type === "text" && <>
                <TextField color="secondary" label={field.label} onChange={e => onChangeField(e, section, field, 'text')} />
              </>}
            </div>;
          })}
        </div>
      </Accordion>
    );
  });

  const onChangeField = (e, section, field, type) => {
    const selections = productSelections.filter(item => item.label !== field.label);
    
    if (type === "date" || type === 'text') {
      setProductSelections([
        ...selections,
        {
          label: field.label,
          section,
          value: e.target.value,
          excludedSection: field.excludeSelection[e.target.value],
        }
      ]);
    }

    if (type === "select") {
      setProductSelections([
        ...selections,
        {
          label: field.label,
          section,
          value: e.target.innerText,
          excludedSection: field.excludeSelection[e.target.innerText],
        }
      ]);
    }

    if (type === "checkbox") {
      setProductSelections([
        ...selections,
        {
          label: field.label,
          section,
          value: e.target.checked,
          excludedSection: field.excludeSelection[e.target.checked],
        }
      ]);
    }
    
  };

  return <div className={!props.show && 'eds-hidden'}>
    <div className="eds--dark">
      <Card className="eds-spacing--mb-32">
        <div className="ncss-row">
          <div className="ncss-col-sm-6 eds-va-t">
            <SelectField
              color="secondary"
              label={t('Product Group')}
              onChange={e => setGroup(e.target.innerText)}
              options={Array.from(groups, x => ({ label: x.name, value: kebab(x.name) }))}
              value={kebab(group)} />
          </div>
          {isNotNilOrEmpty(group) && <div className="ncss-col-sm-6 eds-va-t">
            <SelectField color="secondary" label={t('Subgroup')} onChange={e => setSubGroup(e.target.innerText)} options={groups.filter(x => x.name === group)[0].subGroups.map(x => ({ label: x, value: kebab(x) }))} value={kebab(subGroup)} />
          </div>}
        </div>
      </Card>
    </div>
    {isNotNilOrEmpty(group) ? (
      <>
        <div className="d-sm-flx flx-ai-sm-c eds-spacing--mb-16">
          <div className="flx-gro-sm-1">
            {props.substatus && <Snack className="eds-snack-clean eds-snack-small" status="success"><b>Substatus:</b> Approved</Snack>}
          </div>
          <TextLink onClick={() => setEdit(true)}><Edit /> Edit</TextLink>
        </div>
        {sections.map((section, sectionIndex) => {
          if (isNotNilOrEmpty(subGroup) && section.excludeGroup.includes(`${group}: ${subGroup}`)) {
            return null;
          } 

          if (section.excludeGroup.includes(group) || productSelections.find(x => x.excludedSection?.includes(section.name))) {
            return null;
          }

          if (isNotNilOrEmpty(section.includeGroup) && !section.includeGroup.includes(group)) {
            return null;
          }

          return (
            <Card className="eds-spacing--mb-16" key={`section-read-${sectionIndex}`}>
              <Text font={"title-5"} as={"h2"}><Whiteboard /> {t(section.name)}</Text>
              <div className="ncss-row eds-spacing--mt-4">
                {productSelections.filter(x => x.section.name === section.name).map((field, index) => {
                  if (isNotNilOrEmpty(field.includeSelection)) {
                    for (const [key, value] of Object.entries(field.includeSelection)) {
                      if (productSelections.filter(x => x.label === key && x.value === value).length < 1) {
                        return null;
                      };
                    }
                  }
      
                  if (isNotNilOrEmpty(field.includeGroup)) {
                    if (isNilOrEmpty(subGroup) && !field.includeGroup.includes(group)) {
                      return null;
                    }
                    
                    if (isNotNilOrEmpty(subGroup) && !field.includeGroup.includes(`${group}: ${subGroup}`)) {
                      return null;
                    }
                  }

                  return (
                    <div className="ncss-col-sm-6 eds-spacing--mt-16 eds-va-t" key={`selection-${index}`}><Card className="eds-card-small eds-card-gray"><b>{field.label}:</b> {field.value == true ? 'true' : field.value}</Card></div>
                  )
                })}
              </div>
            </Card>
          );
        })}
      </>
    ) : (
      <Card className="eds-ta-c eds-card-small eds-card-gray eds-spacing--pb-40 eds-spacing--pt-40">
        <Text className="eds-spacing--mb-8" font={"title-3"} as={"h2"}>{t('Select A Group')}</Text>
        <Text font={"paragraph"} as={"p"}>{t('Start entering attribute details by selecting a product group and subgroup.')}</Text>
      </Card>
    )}
    {/* {props.subproduct && <Button className="eds-spacing--mt-16" variant="secondary">{t('Remove Subproduct')}</Button>} */}
    <SidePanel
      footerSlot={() => <>
        <Button className="eds-spacing--mr-12" onClick={() => setEdit(false)}>{t('Apply')}</Button>
        <Button onClick={() => setEdit(false)} variant="secondary">{t('Cancel')}</Button>
      </>}
      headerSlot={() => <h3 className="eds-type--title-3">{t('Edit')}</h3>}
      isOpen={edit}
      large
      onDismiss={() => setEdit(false)}>
        {renderSections()}
    </SidePanel>
  </div>
};

export default Component;